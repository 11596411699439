@font-face {
  font-family: 'Campton-Medium';
  src: local('Campton-Medium'), url('./fonts/Campton-Medium/font.woff') format('woff');
}

@font-face {
  font-family: 'Campton-Light';
  src: local('Campton-Light'), url('./fonts/Campton-Light/font.woff') format('woff');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
